.wrapper {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
}

.container {
  width: 100%;
  padding: 10%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}


.progress {
  display: flex;
  width: 15%;
  min-width: 100px;
  justify-content: space-between;
  margin: auto;
  align-items: center;
}

.step {
  width: 20px;
  height: 20px;
  border-radius: 2rem;
  background-color: rgb(243, 244, 248);
}

.currentStep {
  width: 20px;
  height: 20px;  
  border-radius: 2rem; 
  background-color: rgb(255,111,3);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.backButton {
  margin-bottom: 20px;
  color: #FF6600;
  border: none;
  background: none;
  cursor: pointer;
}

.endRegistration {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2rem;
}

.endButton {
  width: 50%;
  padding: 10px;
  background-color: #FF6600;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.errorMessage {
  color: red;
}

.headerText {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.inputField:focus + label,
.inputField:not(:placeholder-shown) + label {
  top: 20px;
  font-size: 12px;
  color: #FF6600;
}

.nextButton {
  margin-top: 20px;
  cursor: pointer;
  font-size: 24px;
  color: #FF6600;
}

.changeButton {
  background-color: transparent;
  border: none;
  color: #FF6600;
  cursor: pointer;
}

.phoneNumberContainer {
  width: 100%;
  align-items: flex-start;
  align-self: start;
}

.dropdownContainer {
  position: absolute;
  top: 0; /* Moves the dropdown to the top of the page */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensures the dropdown is above other elements */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 50vh; /* Set a max height if needed */
  overflow-y: auto; /* Allow scrolling if there are many options */

}