.button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: #fe6f03;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #e55a00; /* Более темный оттенок при наведении */
}

.buttonDisabled {
  background-color: #d3d3d3; /* Серый цвет для неактивной кнопки */
  color: #a0a0a0; /* Светло-серый цвет текста */
  cursor: not-allowed; /* Курсор "запрещено" */
}

.buttonDisabled:hover {
  background-color: #a9a9a9; /* Более темно-серый при наведении на неактивную кнопку */
}
