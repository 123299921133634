.container {
    padding-top: 2rem;
    height: 100dvh; 
    background-color: #F0EEEE;
}

.title {
    width: 90%;
    margin: auto;
    /* margin-top: 5vh; */
    margin-bottom: 2vh
}