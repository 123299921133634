/* Контейнер для инпута и кнопки */
.inputContainer {
    display: flex;
    align-items: center;
    border-radius: 5px;
    /* overflow: hidden; */
    width: 100%;
    max-width: 400px;
    height: 60px; /* Устанавливаем фиксированную высоту контейнера */
    z-index: 9999;  
}

/* Инпут поля */
.inputField {
    flex: 1;
    height: 100%;
    font-size: 18px;
    height: 60px !important;
    border: 1px solid rgb(230,230,230) !important;
    border-radius: 5px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 100%!important;
    outline: none;
    color: black;
    font-size: 14px;
    padding-left: 5px;
    background-color: transparent;
}

.inputPhone {
   flex: 1;
    height: 100%;
    font-size: 18px;
    height: 60px !important;
    border: 1px solid rgb(230,230,230) !important;
    
    border-radius: 0 !important;
    width: 100%!important;
    outline: none;
    color: black;
    background-color: transparent;
}

.flagDropdownButton {
  background-color: rgb(240,238,238) !important;
}

/* Кнопка продолжения */
.continueButton {
    background-color: rgb(255,111,3);
    color: white;
    font-size: 13px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0 5px 5px 0;
    min-width: 100px;
    height: 100%; /* Кнопка занимает 100% высоты родительского контейнера */
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.continueButton:hover {
    background-color: rgb(248, 120, 22);
}

@media screen and (max-width: 1024px) {
  .inputContainer {
    flex-direction: column;
    justify-content: center;
    height: 102px;
    gap: 10px;
  }
  .continueButton {
    height: 46px;
    width: 100%;
  }
  .inputField {
    width: 100%!important;
  }
}