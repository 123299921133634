.layout {
    width: 80%;
    background-color: white;
    margin: auto;
    padding: 2% 5%;
    border-radius: 10px;
}


.content {
    display: flex;
    justify-content: space-between;
}

.titleContainer {
    display: flex;
    gap: 10px;
    margin-bottom: 40px;
}

.carTitle {
    opacity: 1;
color: rgba(255, 111, 2, 1);

font-size: 22px;
font-weight: 600;
font-style: SemiBold;

}

.infoContainer {
    padding: 1%;
}

.title {
opacity: 1;
color: rgba(146, 146, 147, 1);
font-size: 14px;
font-weight: 400;
font-style: Regular;
}

.text {
opacity: 1;
color: rgba(53, 55, 58, 1);
font-size: 20px;
font-weight: 600;
font-style: SemiBold;
}