.container {
  background: rgb(240, 238, 238);
  /* border-radius: 20px; */
  padding: 2rem 2% 2% 2%;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1rem;
}

.paginationButton {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
}

.paginationButton:disabled {
  background: #f5f5f5;
  cursor: not-allowed;
}

.paginationButton:hover:not(:disabled) {
  background: #f5f5f5;
}

.pageInfo {
  font-size: 0.9rem;
  color: #666;
}
