.container {
    width: 100%;
    background-color: #F0EEEE;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 2rem;
    /* justify-content: center; */
}



/* .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;

}

.content {
    height: 500px;
} */
