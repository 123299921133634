.container {
    width: 100%;
    height: 100vh;
    background-color: #F0EEEE;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;

}

.content {
    width: 30%;
    min-width: 400px;
    align-self: center;
    padding-bottom: 20%;
}