.container {
  width: 100%;
  height: 7vh;
  background-color: white;
  display: flex;
  font-size: 16px;
  flex-direction: row;
  justify-content: center;
  padding: 10px 10% 10px 10%;
  box-sizing: border-box;
  align-items: center;
}

.menu_point {
  white-space: nowrap;
  text-decoration: none;
  color: black;
  align-self: center;
}

.menu_point.active {
  border-bottom: 2px solid rgb(255, 110, 4);
}

.favouriteCount {
  background-color: #f0eeee;
  border-radius: 19px;
  margin-left: 7px;
  padding: 3px 9px 3px 9px;
  color: #979797;
  font-family: "Golos Text";
}

.menu_logo {
  margin-top: 0.7vh;
  padding-right: 1rem;
  align-self: center;
}

.menu {
  overflow-y: none;
  overflow-x: scroll;
  flex-grow: 5;
  display: flex;
  flex-direction: row;
  gap: 1.3rem;
}

.user {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  position: relative; /* Добавляем позиционирование для родителя */
}

.userMenuTrigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 5px; /* Немного padding для удобного клика */
  border-radius: 4px;
}

.userMenuTrigger:hover {
  background-color: #e0e0e0;
}

.user_name {
  margin: 0;
  user-select: none; /* Отключаем выделение текста */
}

.user svg {
  width: 24px;
  height: 24px;
}

.menu_point_market {
  margin-left: 40px;
}

.login_button {
  background-color: #ff6600;
  color: white;
  padding: 10px 40px;
  border-radius: 10px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.login_button:hover {
  background-color: #e65c00;
}

/* Стиль для всплывающего меню */
.dropdownMenu {
  position: absolute;
  top: 100%; /* Расположить под иконкой */
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px;
  border-radius: 4px;
  width: 150px;
  display: flex;
  flex-direction: column;
}

.dropdownItem {
  display: block;
  padding: 8px 12px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}
.exitButton {
  border: 2px solid;
  border-color: #ff6600;
  background-color: white;
  color: #ff6600;

  border-radius: 20px;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

/* Для скрытия меню, если оно не открыто */
.hidden {
  display: none;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
  .container {
    padding: 10px 20px;
    justify-content: space-between;
    position: relative;
    z-index: 999; /* Чтобы навбар был поверх мобильного меню */
  }

  .desktopMenu,
  .user {
    display: none;
  }

  .burgerMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 1002;
  }

  .burgerMenu span {
    width: 100%;
    height: 2px;
    background-color: #e65c00;
    transition: all 0.3s ease;
  }

  .burgerMenu.open span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .burgerMenu.open span:nth-child(2) {
    opacity: 0;
  }

  .burgerMenu.open span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -7px);
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    right: -100%; /* Меняем right на left для анимации слева */
    width: 100%; /* Меню на всю ширину */
    height: 100vh;
    background-color: white;
    transition: right 0.3s ease; /* Меняем right на left */
    z-index: 1000;
  }

  .mobileMenu.open {
    right: 0; /* Меняем right на left */
  }

  .mobileMenuContent {
    padding: 60px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
  }

  .mobileMenuContent a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }

  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 32px;
    background: none;
    border: none;
    cursor: pointer;
    color: #e65c00;
  }

  .mobileFavouriteCount {
    background-color: #f0eeee;
    border-radius: 19px;
    margin-left: 10px;
    padding: 3px 9px;
    color: #979797;
  }

  .mobileUserInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }

  .mobileUserInfo svg {
    width: 24px;
    height: 24px;
  }

  .mobileLoginButton {
    background-color: #ff6600 !important;
    color: white !important;
    padding: 15px !important;
    border-radius: 10px !important;
    text-align: center;
    font-weight: bold;
    margin-top: auto; /* Прижимаем кнопку к низу */
    border: none !important;
  }

  .mobileLogoutButton {
    background: none;
    border: 2px solid #ff6600;
    border-radius: 10px;
    color: #ff6600;
    padding: 15px;
    font-size: 16px;
    cursor: pointer;
    margin-top: auto; /* Прижимаем кнопку к низу */
    font-weight: bold;
  }

  /* Скрываем десктопные элементы */
  .menu,
  .user .userMenuTrigger {
    display: none;
  }
}

/* Стили для десктопной версии */
@media (min-width: 769px) {
  .burgerMenu,
  .mobileMenu {
    display: none;
  }
}
