.dropdownContainer {
  position: relative;
  width: 100%; /* Можно изменить ширину по необходимости */
  max-width: 100%;
}

.dropdown {
  padding: 10px;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%; /* Можно изменить ширину по необходимости */
  max-width: 100%;
}

.open {
  border-color: black;
}

.selectedText {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  opacity: 1;
  color: rgba(53, 55, 58, 1);
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
}

.placeholder {
  color: rgb(236, 236, 236);
}

.arrow {
  margin-left: auto;
}

.values {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid rgb(236, 236, 236);
  background: white;
  z-index: 1000;
  width: 250px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.option_group {
  font-weight: 700;
  padding-left: 4px;
  color: #2c3e50;
}


.option {
  padding: 10px;
  padding-left: 15px;
  cursor: pointer;
  opacity: 1;
  color: rgba(53, 55, 58, 1);
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
}

.option:hover {
  background-color: #ebedfb;
}

.selected {
  background-color: #fff0e5;
}

.label {
  position: absolute;
  top: 15px;
  left: 10px;
  pointer-events: none;
  transition: all 0.2s;
  color: #888;
  background: white;
  padding: 0 5px;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
}

.label.floating {
  top: -10px;
  opacity: 1;
  color: rgba(146, 146, 147, 1);
  font-size: 12px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
}
.okButton {
  all: unset;
  border-radius: 5px;
  opacity: 1;
  background-color: rgba(255, 111, 2, 1);
  padding: 10px;
  color: white;
}

.deleteButton {
  all: unset;
  border-radius: 5px;
  border: 1px solid rgba(255, 111, 2, 1);
  opacity: 1;
  padding: 10px;
  color: rgba(255, 111, 2, 1);
}

@media screen and (max-width: 1024px) {
  .selectedText {
    font-size: 14px;
    margin-left: 0px !important;
  }

  .value {
    font-size: 14px;
  }

  .floatingLabel {
    font-size: 14px;
  }
}

.disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
  border: 1px solid rgb(208, 208, 208);
}

.disabled .label {
  color: #999;
  background: transparent;
}

.disabled .selectedText {
  color: #666;
}

/* При наведении на disabled элемент */
.disabled:hover {
  border-color: #e6e6e6;
}

.disabled .placeholder {
  opacity: 0;
}

/* Медиа-запросы для адаптивности */
@media screen and (max-width: 1024px) {
  .dropdown.disabled .selectedText {
    font-size: 14px;
    opacity: 0.7;
  }

  .dropdown.disabled .label {
    font-size: 14px;
  }
}
