.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  --position-multiplier: 3; /* Desktop multiplier */
}

@media screen and (max-width: 768px) {
  .overlay {
    --position-multiplier: 1.5; /* Mobile multiplier */
  }
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  width: 400px;
  animation: slideIn 0.3s ease-out;
}

.popup.positioned {
  position: fixed;
  margin: 0;
  transform-origin: top left;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
