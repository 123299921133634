@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.skeleton {
  background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
  background-size: 800px 104px;
  animation: shimmer 1.5s infinite linear;
  border-radius: 4px;
}

.carCard {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.imageWrapper {
  width: 100%;
  height: 200px;
  border-radius: 12px 12px 0 0;
}

.carInfo {
  padding: 16px;
}

.price {
  height: 24px;
  width: 80px;
  margin-bottom: 8px;
}

.model {
  height: 20px;
  width: 140px;
  margin-bottom: 8px;
}

.details {
  height: 16px;
  width: 120px;
}
