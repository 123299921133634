.container {
  width: 90%;
  background-color: white;
  margin: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.content {
  width: 100%;
}
