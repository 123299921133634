.container {
  width: 90%;
  min-height: 100%;
  margin: auto;
  padding: 2rem 5%;
  background-color: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.layout {
  width: 100%;
  background-color: rgb(240, 238, 238);
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.bottomRow {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.brandDetails {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.brandDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 60px;
}

.rowElement {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rangeRowElement {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rangeRowElementRegions {
  min-width: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .rangeRowElementRegions {
    max-width: 890px;
  }
}

.mainFilterContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  row-gap: 20px;
  column-gap: 60px;
}
.secondaryFilterContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  row-gap: 20px;
  column-gap: 60px;
}

/* Медиа-запросы для адаптивности */

/* Планшеты */
@media (max-width: 1024px) {
  .container {
    width: 95%;
    padding: 1.5rem 2.5%;
  }

  .mainFilterContainer {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }

  .brandDetail {
    flex-direction: column;
    gap: 20px;
  }

  .row {
    flex-direction: column;
    gap: 15px;
  }

  .rowElement {
    width: 100%;
  }
  .secondaryFilterContainer {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}

/* Мобильные устройства */
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 1rem;
    border-radius: 0;
  }

  .mainFilterContainer {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .brandDetail {
    gap: 15px;
  }

  .bottomRow {
    flex-direction: column;
    align-items: stretch;
  }

  .delete {
    height: 2.5rem;
    width: 2.5rem;
  }

  .filterNameInput {
    width: 100%;
  }
}

/* Маленькие мобильные устройства */
@media (max-width: 480px) {
  .container {
    padding: 0.5rem;
  }

  .brandDetails {
    gap: 15px;
  }

  .savedFilterItem {
    padding: 6px 8px;
  }

  .button {
    font-size: 14px;
  }
}

/* Сохраняем остальные стили без изменений */
.allParametrs {
  color: rgb(254, 111, 3);
  display: flex;
  gap: 10px;
  align-items: center;
}

.delete {
  color: rgb(151, 151, 151);
  display: flex;
  align-items: center;
  border: none;
  background-color: #f0eeee;
  border-radius: 9999px;
  padding: 0.5rem;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  pointer-events: auto;
  cursor: pointer;
}

.delete:hover {
  transform: rotate(90deg);
  opacity: 0.7;
}

.delete:hover svg path {
  stroke: #ff0000;
}

.selectorDisabled {
  background-color: #f0f0f0; /* Светло-серый цвет фона */
  color: #a0a0a0; /* Серый цвет текста */
  cursor: not-allowed; /* Указываем, что элемент не активен */
}

.delete {
  pointer-events: auto;
  cursor: pointer; /* Убедитесь, что курсор меняется на указатель */
}

.delete:focus,
.delete:hover {
  color: #ff0000; /* Пример: изменение цвета при наведении */
}

.button {
  color: #979797;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.button:hover {
  color: #ff0000;
}

.savedFiltersContainer {
  position: relative;
}

.savedFilterItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
}

.savedFilterItem:last-child {
  border-bottom: none;
}

.savedFilterName {
  flex-grow: 1;
  padding-right: 8px;
  transition: opacity 0.2s;
}

.savedFilterItem:hover {
  background-color: #f5f5f5;
}

.deleteFilterButton {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 18px;
  padding: 0 4px;
}

.deleteFilterButton:hover {
  color: #ff6e04;
}

.filterNameInput {
  padding: 8px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  min-width: 200px;
}

.saveButton {
  padding: 8px 16px;
  background-color: #ff6e04;
  color: white;
  border: none;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
}

.saveButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.loading {
  opacity: 0.7;
  cursor: wait;
}

.saveDialog {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
  height: 200px;
  overflow: scroll;
  padding: 12px;
}

.saveNewFilter {
  padding-bottom: 12px;
  margin-bottom: 12px;
  height: 200px;
}

.filterNameInput {
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.saveButton {
  width: 100%;
  padding: 8px;
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.saveButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.savedFiltersDropdown {
  border-top: 1px solid #e6e6e6;
  padding: 4px;
  height: 150px;
  overflow: hidden;
  overflow: scroll;
}

.savedFilterItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
}

.savedFilterItem:hover {
  background-color: #f5f5f5;
}

.savedFilterName {
  flex-grow: 1;
}

.deleteFilterButton {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  padding: 0 8px;
  font-size: 18px;
}

.deleteFilterButton:hover {
  color: #ff0000;
}

.addButton {
  background-color: #f0eeee;
  color: #979797;
  font-size: 30px;
  border: none;
  border-radius: 9999px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  padding: 0.2rem 0.9rem;
  width: 40px;
  height: 40px;
}

.addButton:hover {
  opacity: 0.7;
  transform: rotate(180deg);
}

.removeButton {
  background-color: #f0eeee;
  color: #979797;
  font-size: 30px;
  border: none;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  padding: 0.2rem 0.9rem;
  width: 40px;
  height: 40px;
}

.removeButton:hover {
  opacity: 0.7;
  transform: rotate(180deg);
}

.metricsList {
  list-style: none;
  display: grid;
  gap: 20px;
  padding: 0;
  margin: 0;
  width: 100%;

  /* Адаптивная grid сетка */
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  justify-content: center;
  align-items: start;
}

.metricsList li {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  padding: 12px;
}

.mertricsTitle {
  color: #929293;
  font-size: 14px;
  margin: 0;
}

.mertricsDesc {
  color: #35373a;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Loader.module.css */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: rgb(255, 157, 0);
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
