.container {
  display: flex;
  gap: 20px;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
}

.default {
  transition: all 0.2s;
  cursor: pointer;
  white-space: nowrap;
  user-select: none; /* Запрещаем выделение текста */
}

.selected {
  background-color: rgb(255, 110, 4);
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  transition: all 0.2s;
  cursor: pointer;
  user-select: none; /* Запрещаем выделение текста */
}
