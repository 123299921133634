@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
  border-radius: 4px;
}

.container {
  display: grid;
  grid-template-columns: 0.8fr 0.6fr 1.2fr 0.5fr 0.6fr 0.6fr 0.8fr 0.6fr 0.8fr;
  align-items: center;
  padding: 16px;
  gap: 12px;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.image {
  width: 100%;
  height: 80px;
  border-radius: 8px;
}

.dateWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.time {
  height: 16px;
  width: 60%;
}

.date {
  height: 14px;
  width: 80%;
}

.title {
  height: 16px;
  width: 90%;
}

.year {
  height: 16px;
  width: 50%;
}

.price {
  height: 16px;
  width: 70%;
}

.mileage {
  height: 16px;
  width: 60%;
}

.engineWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.transmission {
  height: 16px;
  width: 75%;
}

.engine {
  height: 14px;
  width: 60%;
}

.location {
  height: 16px;
  width: 65%;
}

.iconsWrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

/* Mobile Container */
.containerMobile {
  display: none;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.imageMobile {
  width: 100%;
  height: 200px;
}

.infoMobile {
  padding: 16px;
}

.titleMobile {
  height: 20px;
  width: 80%;
  margin-bottom: 16px;
}

.detailsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 16px;
}

.detail {
  height: 36px;
  width: 100%;
  border-radius: 8px;
}

.footerMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #ecf0f1;
}

.priceMobile {
  height: 24px;
  width: 100px;
}

.iconsMobile {
  display: flex;
  gap: 12px;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .container {
    grid-template-columns: 0.8fr 0.6fr 1.2fr 0.5fr 0.6fr 0.6fr 0.8fr 0.6fr 0.8fr;
  }
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: 0.8fr 0.6fr 1.2fr 0.5fr 0.6fr 0.6fr 0.8fr 0.6fr 0.8fr;
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    display: none;
  }

  .containerMobile {
    display: block;
  }
}
