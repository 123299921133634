.container {
  width: 100%;
  display: grid;
  grid-template-columns: 100px 80px minmax(200px, 1fr) 80px 80px 80px 80px 80px minmax(
      100px,
      1fr
    );
  background-color: white;
  margin: auto;
  padding: 16px;
  box-shadow: 0px 4px 8px rgba(211, 211, 211, 0.5);
  z-index: 5;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  gap: 20px;
  align-items: center;
}

.column {
  display: flex;
  justify-content: start;
  align-items: start;
  text-align: start;
}

.column p {
  opacity: 1;
  color: rgba(151, 151, 151, 1);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sortableColumn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.sortableColumn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Медиа-запросы */
@media screen and (max-width: 1200px) {
  .container {
    grid-template-columns: 70px 70px minmax(180px, 1fr) 70px 70px 70px 70px 70px minmax(
        90px,
        1fr
      );
  }
}

@media screen and (max-width: 1100px) {
  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    padding: 16px;
  }

  .column {
    width: auto;
  }

  .column p {
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    padding: 12px;
    gap: 8px;
  }

  .column p {
    font-size: 10px;
  }
}
