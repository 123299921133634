.container {
  display: flex;
  justify-content: space-between;  
  align-items: center;           
  position: relative;
  padding: 20px 5%;                
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  color: #666;                    
  background-color: white;        
}

.left {
  font-weight: bold;
}

.center {
  position: absolute;
  top: 50%;                        
  left: 50%;                       
  transform: translate(-50%, -50%); 
  display: flex;
  flex-direction: column;
  text-align: center;
  color: black;    
  font-weight: bold;   
  font-size: 1vw;             
}

.center a {
  text-decoration: none;      
  color: black;
  margin-bottom: 5px;         
  transition: color 0.3s ease;
}

.center a:hover {
  color: rgb(195, 75, 255);
}

/* Правый блок */
.right a {
  text-decoration: none;
  color: black;
  transition: color 0.3s ease;
  font-weight: bold;
}

.right a:hover {
  color: rgb(195, 75, 255);
}

/* Подвал с информацией */
.subFooter {
  text-align: center;
  padding: 20px 5%;
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  color: #999;  /* Светло-серый текст */
  background-color: white;
  line-height: 1.6;
}

.subFooter p {
  margin: 5px 0;
}

/* Адаптивность */
@media (max-width: 768px) {
  .container {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .center {
      position: relative;
      transform: none;
      top: auto;
      left: auto;
      font-weight: bold;
      font-size: 2.5vw;   
      padding-bottom: 30px;
  }

  .subFooter {
      font-size: 10px;
      padding: 15px 5%;
  }
}