.container {
  width: 90%;
  margin: auto;
}

.content {
  display: flex;
  background-color: white;
  padding: 2%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
  }
}

.layout {
  background-color: #f0eeee;
  height: 100dvh;
}

.sizedbox {
  height: 40px;
  background-color: #f0eeee;
}
