.container {
  width: 80%;
  align-self: center;
  background-color: white;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  box-sizing: border-box;
}

.rowElement {
  flex-shrink: 0;
  width: 32%;
}

@media (max-width: 768px) {
  .rowElement {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.mainRowElement {
  width: 100%;
}

/* Новые стили для строки с кнопками и кнопки "Подбор авто" */
.headerRow {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between; /* Это добавляет отступ между кнопками и "Подбор авто" */
  align-items: center;
  width: 100%;
}

.autoSelectButton {
  display: flex;
  justify-content: flex-end;
}

.selectButton {
  background-color: transparent;
  border: 1px solid #ff6600;
  border-radius: 15px;
  padding: 10px 20px;
  color: #ff6600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Golos Text", sans-serif; /* Применение шрифта */
}

.selectButton:hover {
  background-color: #ff6600;
  color: white;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  transition: filter 0.3s ease; /* Переход для цвета иконки */
}

.selectButton {
  background-color: transparent;
  border: 2px solid #ff6600;
  border-radius: 20px;
  padding: 5px 20px;
  color: #ff6600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Переход для фона и текста */
}

.selectButton:hover {
  background-color: #ff6600; /* Оранжевый фон при наведении */
  color: white; /* Белый текст при наведении */
}

.selectButton:hover .icon {
  filter: invert(1); /* Белые очки при наведении */
}
