.carListOverflow {
  width: 100%;
  margin: auto;
}

.container {
  width: 80%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #2c3e50;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  position: relative;
  place-items: center;
}

.button {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
}

.loading {
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .content {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}

@media screen and (max-width: 950px) {
  .container {
    padding: 16px;
  }

  .content {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
}

@media screen and (max-width: 590px) {
  .container {
    padding: 12px;
  }

  .content {
    grid-template-columns: 1fr;
    gap: px;
  }
}
