.content {
    width: 100%;
    background-color: white;
    padding: 2%;
    border-radius: 10px;
    
}


.container {
    width: 90%;
    margin: auto;
    margin-bottom: 40px;
    align-items: center;
}

.layout {
    background-color: #F0EEEE;
    width: 100%;
    min-height: 100dvh;
    padding-top: 40px;
}

.header_info {
    text-align: center;
}

.text_info {
    text-align: center;
}