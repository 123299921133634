.input {
  all: unset;
  background-color: white;
  max-height: 50px;
  height: 50px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 4px;
  text-align: left;
  padding-left: 10px;
}
