.dropdownContainer {
  position: relative;
  width: 100%;
  min-width: 200px;
}

.dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  width: 100%;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s;
  height: 50px;
}

.dropdown.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #000;
  background-color: #f9f9f9; /* Лёгкое затемнение активного дропдауна */
  z-index: 1100;
  position: relative;
}

.dropdown.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #f0f0f0; /* Менее яркий цвет для неактивного */
  border-color: #d0d0d0; /* Тусклая граница */
}

.placeholder {
  color: #999;
}

.selectedText {
  opacity: 1;
  color: rgba(53, 55, 58, 1);
  font-size: 16px;
  font-weight: 400;
  flex-grow: 1;
  margin-left: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow {
  font-size: 12px;
  margin-left: auto;
}

.values {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-top: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.value {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
}

.value:hover {
  background-color: #f5f5f5;
}

.selected {
  background-color: #ff6e0410;
  color: #ff6e04;
}

.floatingLabel {
  position: absolute;
  left: 10px;
  top: 15px;
  pointer-events: none;
  transition: all 0.2s ease;
  opacity: 1;
  color: rgba(146, 146, 147, 1);
  font-size: 16px;
}

.floatingLabelActive {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: rgb(146, 146, 147);
  background-color: white;
  border-radius: 4px;
  padding: 0 5px;
}

.button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: #fe6f03;
  color: white;
  border-radius: 5px;
  height: 36px;
  width: 20%;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #e55a00; /* Более темный оттенок при наведении */
}

.buttonDisabled {
  background-color: #d3d3d3; /* Серый цвет для неактивной кнопки */
  color: #a0a0a0; /* Светло-серый цвет текста */
  cursor: not-allowed; /* Курсор "запрещено" */
}

.buttonDisabled:hover {
  background-color: #a9a9a9; /* Более темно-серый при наведении на неактивную кнопку */
}

.input {
  all: unset;
  background-color: white;
  height: 34px;
  color: rgb(0, 0, 0);
  border: 1px solid rgb(230, 230, 230);
  text-align: left;
  padding-left: 10px;
  width: 80%;
  border-radius: 4px;
}

.saveFiltersContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dropdownContainer {
  position: relative;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.selectedText {
  flex-grow: 1; /* Позволяет тексту занимать доступное пространство */
  margin-left: 10px;
  text-align: left;
}

.typedNameInput {
  flex-grow: 1;
  margin-left: 10px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  border: none;
  background-color: transparent;
}

.typedNameInput:focus {
  outline: none;
}

/* Стили для кнопки крестика */
.clearIcon {
  color: grey; /* Цвет иконки */
  cursor: pointer; /* Указатель при наведении */
  font-size: 22px; /* Увеличиваем размер иконки */
  margin-left: 8px; /* Отступ слева */
}

/* Hover-эффект для кнопки крестика */
.clearIcon:hover {
  color: darkgrey; /* Цвет при наведении */
}

/* Стиль для стрелки */
.arrow {
  margin-right: 10px; /* Оставляем место для крестика */
}

/* Стили для стрелки, если крестика нет */
.dropdown:not(:has(.clearIcon)) .arrow {
  margin-right: 10px; /* Сдвигаем вправо, если крестика нет */
}

@media screen and (max-width: 1024px) {
  .selectedText {
    font-size: 14px;
    margin-left: 0px !important;
  }

  .value {
    font-size: 14px;
  }

  .floatingLabel {
    font-size: 14px;
  }
}

.dropdown.disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

.dropdown.disabled:hover {
  border-color: #e6e6e6;
}

.searchContainer {
  padding: 8px;
  border-bottom: 1px solid #e6e6e6;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.searchInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.searchInput:focus {
  border-color: #ff6e04;
}

.valuesList {
  max-height: 200px;
  overflow-y: auto;
}

.valuesList::-webkit-scrollbar {
  width: 6px;
}

.valuesList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.valuesList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.valuesList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.noResults {
  padding: 12px;
  text-align: center;
  color: #666;
  font-style: italic;
}

.option_group {
  font-weight: 700;
  padding-left: 4px;
  color: #2c3e50;
}

@media (max-width: 768px) {
  .searchInput {
    padding: 6px;
    font-size: 13px;
  }

  .valuesList {
    max-height: 150px;
  }

  .value {
    padding: 6px 10px;
    padding-left: 15px;
  }
}
