.container {
  display: flex;
  gap: 10px; /* Пространство между чекбоксом и лейблом */
  align-items: center;
}

.checkbox {
  width: 18px; /* Размер чекбокса */
  height: 18px;
  border-radius: 3px;
  background-color: white; /* Фон по умолчанию */
  border: 2px solid #ccc; /* Серый бордер */
  appearance: none; /* Убираем стандартный вид */
  cursor: pointer;
  transition: all 0.2s;
  position: relative; /* Для позиционирования галочки */
}

.checkbox:checked {
  background-color: rgb(255, 110, 4); /* Оранжевый фон при выборе */
  border-color: rgb(255, 110, 4); /* Оранжевый бордер при выборе */
}

.checkbox:checked::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"%3E%3Cpolyline points="20 6 9 17 4 12"%3E%3C/polyline%3E%3C/svg%3E');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px; /* Размер галочки */
  height: 12px;
}

.label {
  cursor: pointer;
  user-select: none; /* Запрет на выделение текста */
  font-size: 14px;
  transition: all 0.3s; /* Плавный переход */
  text-wrap: nowrap;
}

.label:hover {
  color: rgb(255, 110, 4); /* Цвет текста при наведении */
  transform: scale(1.1); /* Увеличение текста при наведении */
}
