.locale {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  align-items: center;
}

.droptrigger {
  display: flex;
  flex-direction: row;
  gap: 4px;
  cursor: pointer;
  align-items: center;
  color: #a5a6a7;
}

.languageSwitcherContainer {
  position: relative;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 100;
  border-radius: 12px;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 12px;
}

.dropdownItem:hover {
  background-color: #f1f1f1;
}

.dropdownItem p {
  margin-left: 10px;
}
