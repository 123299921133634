@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.carCard {
  width: 23%;
  min-width: 290px;
  min-height: 218px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  transition: transform 0.2s ease-in-out; /* Плавный переход для эффекта увеличения */
}

.carCard:hover {
  transform: scale(1.02); /* Увеличение карточки при наведении */
}

.carImageWrapper {
  position: relative;
  width: 100%;
  height: 180px;
  width: 280px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f8f8f8 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 20px;
  overflow: hidden;
  transition: background 0.3s ease-in-out;
}

/* Когда изображение загружено, убираем градиент и анимацию */
.carImageWrapper.loaded {
  background: none;
  animation: none;
}

.carImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 20px;
}

/* Когда изображение загружено, оно плавно появляется */
.carImageWrapper.loaded .carImage {
  opacity: 1;
}

.carInfo {
  width: 100%;
  padding-top: 10px;
  font-family: "Golos Text", sans-serif;
}

@media (max-width: 768px) {
  .carInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.carPrice {
  font-size: 24px;
  font-weight: 600;
  color: rgba(53, 55, 58, 1);
  margin-bottom: 5px;
}

.carModel {
  font-size: 16px;
  font-weight: 400;
  color: rgba(53, 55, 58, 1);
  margin-bottom: 5px;
}

.carDetails {
  font-size: 14px;
  font-weight: 400;
  color: rgba(146, 146, 147, 1);
}

.grid {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
