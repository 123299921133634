.carList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; /* Зазор между элементами */
  max-width: 100%;
  padding: 40px;
  column-gap: 110px;
  /* Для центрирования по горизонтали */
  justify-content: center;
}

@media (max-width: 1200px) {
  .carList {
    grid-template-columns: repeat(3, 1fr); /* На мобильных 4 колонки */
    overflow: scroll;
  }
}

@media (max-width: 970px) {
  .carList {
    grid-template-columns: repeat(2, 1fr); /* На мобильных 4 колонки */
    overflow: scroll;
  }
}

@media (max-width: 660px) {
  .carList {
    grid-template-columns: repeat(1, 1fr); /* На мобильных 4 колонки */
    place-items: center;
    overflow: scroll;
    height: 300px;
  }
}
.carItem {
  width: 150px; /* Ширина каждого элемента */
  display: flex;
  /* gap: 16px; */
  justify-content: space-between;
  font-size: 18px;
  color: #333;
  cursor: pointer;
  transition: transform 0.1s ease; /* Плавный переход для трансформации */
}

.carItem:hover {
  transform: scale(1.05); /* Увеличение при наведении */
}

.carBrand,
.carCount {
  font-size: 16px;
  transition: transform 0.3s ease, color 0.3s ease; /* Плавный переход для текста */
}

.carItem:hover .carBrand,
.carItem:hover .carCount {
  color: #ff6600; /* Изменение цвета текста при наведении (по желанию) */
  transform: scale(1.02); /* Небольшое увеличение текста */
}

.carBrand {
  opacity: 1;
  color: rgba(53, 55, 58, 1);
  font-size: 15px;
  font-weight: 500;
  min-width: 120px;
}

.carCount {
  opacity: 1;
  color: rgba(146, 146, 147, 1);
  font-size: 15px;
  font-weight: 400; /* Отменили жирный текст для количества машин */
}

.skeletonItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  height: 30px;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  animation: shimmer 1.2s infinite linear;
  overflow: hidden;
  position: relative;
}

.skeletonBrand,
.skeletonCount {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.skeletonBrand {
  width: 60%;
}

.skeletonCount {
  width: 20%;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
