.container {
  width: 100%;
  display: grid;
  grid-template-columns: 80px 80px minmax(200px, 1fr) 80px 80px 80px 80px 80px minmax(
      100px,
      1fr
    );
  background-color: white;
  padding: 2%;
  margin: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  gap: 4px;
}

.layout {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.image {
  width: 100%;
  border-radius: 3.85px;
}

.boldText {
  opacity: 1;
  color: rgba(53, 55, 58, 1);
  font-size: 16px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
}

.carNameText {
  opacity: 1;
  color: rgba(255, 111, 2, 1);
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
}

.smallText {
  opacity: 1;
  color: rgba(151, 151, 151, 1);
  font-size: 12px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
}

.icons {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 80%;
}

.header_info {
  text-align: center;
  padding: 20px;
}

.text_info {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .layout {
    width: 95%;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 16px;
  }
}

@media screen and (max-width: 768px) {
  .layout {
    width: 100%;
  }
}
