.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.label {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.container {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 0.3rem;
  width: 100%;
  display: flex;
  transition: border-color 0.2s ease;
}

.container.focused {
  border-color: #007bff;
}

.inputLeft {
  all: unset;
  border-right: 1px solid rgb(230, 230, 230);
  padding: 15px;
  width: 50%;
}

.inputRight {
  padding: 15px;
  all: unset;
  width: 50%;
  padding-left: 10px;
}

/* Скрываем стрелки для number input */
.inputLeft::-webkit-outer-spin-button,
.inputLeft::-webkit-inner-spin-button,
.inputRight::-webkit-outer-spin-button,
.inputRight::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputLeft[type="number"],
.inputRight[type="number"] {
  -moz-appearance: textfield;
}

/* Placeholder стили */
.inputLeft::placeholder,
.inputRight::placeholder {
  color: rgb(180, 180, 180);
}

.inputError {
  outline: 1px solid red;
}
