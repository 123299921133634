.container {
  width: 50%;
  height: auto;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .container {
    width: 100%;
    position: relative;
  }
}
