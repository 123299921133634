.container {
  display: grid;
  grid-template-columns: 100px 80px minmax(200px, 1fr) 80px 80px 80px 80px 80px minmax(
      100px,
      1fr
    );
  padding: 16px;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 0px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  gap: 20px;
}

.container div {
  width: 100%;
}

.container:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  background: #fafafa;
}

.carImageWrapper {
  position: relative;
  width: 100px !important;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.carImage.loaded {
  opacity: 1;
}

.boldText {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  color: #2c3e50;
}

.smallText {
  font-size: 12px;
  color: #7f8c8d;
  margin: 4px 0 0 0;
}

.titleInfo {
  width: 100px;
}

.carNameText {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  color: #e67e22;
  transition: color 0.3s ease;
}

.container:hover .carNameText {
  color: #d35400;
}

.icons {
  display: flex;
  gap: 12px;
  align-items: center;
}

.icons > * {
  transition: transform 0.2s ease;
}

.icons > *:hover {
  transform: scale(1.1);
}

/* Mobile Container */
.containerMobile {
  display: none;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.containerMobile:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.carImageMobile {
  width: 100%;
  height: 200px;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.carImageMobile.loaded {
  opacity: 1;
}

.infoMobile {
  padding: 16px;
}

.carNameTextMobile {
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 8px 0;
  color: #e67e22;
}

.fullInfoMobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 12px;
}

.fullInfoMobile p {
  background: #f7f9fc;
  padding: 8px 12px;
  border-radius: 8px;
  margin: 0;
  font-size: 13px;
  color: #7f8c8d;
  transition: background-color 0.3s ease;
}

.fullInfoMobile p:hover {
  background: #eef2f7;
}

.priceContainerMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #ecf0f1;
}

.priceMobile {
  font-weight: 700;
  font-size: 18px;
  color: #2c3e50;
  margin: 0;
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
  .container {
    gap: 8px;
    padding: 12px;
  }

  .carImageWrapper {
    height: 70px;
  }
}

/* @media screen and (max-width: 1024px) {
  .container {
  }

  .carImageWrapper {
    height: 60px;
  }

  .boldText {
    font-size: 13px;
  }

  .smallText {
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .carNameText {
    font-size: 13px;
  }

  .boldText,
  .smallText {
    font-size: 12px;
  }
} */

@media screen and (max-width: 1200px) {
  .container {
    display: none;
  }

  .containerMobile {
    display: block;
  }

  .infoMobile {
    display: flex;
    flex-direction: column;
  }

  .fullInfoMobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .priceContainerMobile {
    margin-top: 16px;
  }
}

.loginPopup {
  text-align: center;
}

.loginPopup h3 {
  margin: 0 0 10px;
  color: #2c3e50;
}

.loginPopup p {
  margin: 0 0 20px;
  color: #7f8c8d;
}

.popupButtons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.popupButtons button:last-child {
  background-color: #95a5a6;
}

.popupButtons button:last-child:hover {
  background-color: #7f8c8d;
}
