.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 768px) {
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sectionName {
  font-size: 30px;
  font-weight: 600;
}

.text {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 1rem 0;
}

.textBold {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 8px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.codeInputs {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 2rem 0;
}

.codeInput {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  font-size: 1.2rem;
}

.timer {
  color: #666;
  margin: 1rem 0;
}

.resendButton {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.modalButtons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.successMessage {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 25px;
  background-color: #4caf50;
  color: white;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  text-align: center;
  animation: slideDown 0.3s ease-out;
}

.errorMessage {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 25px;
  background-color: #f44336;
  color: white;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  text-align: center;
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 20px;
    opacity: 1;
  }
}
