@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400..900&display=swap');

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	font-family: "Golos Text", sans-serif;
}

html {
	width: 100%;
}
@font-face {
	font-family: 'Golos Text Bold';
	src: url('./fonts/golos_text_bold.eot'); /* IE 9 Compatibility Mode */
	src: url('./fonts/golos_text_bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
		url('./fonts/golos_text_bold.woff2') format('woff2'), /* Super Modern Browsers */
		url('./fonts/golos_text_bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
		url('./fonts/golos_text_bold.ttf') format('truetype'), /* Safari, Android, iOS */
		url('./fonts/golos_text_bold.svg#golos_text_bold') format('svg'); /* Chrome < 4, Legacy iOS */
}